import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

const NgcNavbar = () => (
  <Navbar id="home" bg="light" expand="lg" collapseOnSelect>
    <Navbar.Brand href="/#home">Neil Hendry</Navbar.Brand>
    <Navbar.Toggle aria-controls="navbar-nav" />
    <Navbar.Collapse id="navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link href="/#about">About Neil</Nav.Link>
        <Nav.Link href="/#events">Events</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
)

export default NgcNavbar
