import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

const Footer = () => {

  const { allMarkdownRemark: pages } = useStaticQuery(
    graphql`
      query pagesStaticQuery {
        allMarkdownRemark(filter: {frontmatter: {templateKey: {in: ["feature","page"]}}}) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                templateKey
                title
              }
            }
          }
        }
      }
    `
  )

  const features = pages.edges.filter(({ node }) => node.frontmatter.templateKey === "feature")
  const content = pages.edges.filter(({ node }) => node.frontmatter.templateKey === "page")

  return (
    <footer className="footer">
      <div className="container footer__wrapper">
        <div className="footer__column">
          <ul className="footer__list">
            <li className="footer__list-item"><Link className="footer__link" to="/">Home</Link></li>
            <li className="footer__list-item"><Link className="footer__link" to="/#about">About Neil</Link></li>
            <li className="footer__list-item"><Link className="footer__link" to="/#events">Events</Link></li>
          </ul>
        </div>
        { features.length > 0 &&
            <div className="footer__column">
              <h3 className="footer__column-heading">Features</h3>
              <ul className="footer__list">
                { features.map(({ node }) => (
                    <li
                      key={ node.id }
                      className="footer__list-item"
                    >
                      <Link className="footer__link" to={ node.fields.slug }>
                        { node.frontmatter.title }
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </div>
        }
        { content.length > 0 &&
            <div className="footer__column">
              <h3 className="footer__column-heading">Content</h3>
              <ul className="footer__list">
                { content.map(({ node }) => (
                    <li
                      key={ node.id }
                      className="footer__list-item"
                    >
                      <Link className="footer__link" to={ node.fields.slug }>
                        { node.frontmatter.title }
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </div>
        }
      </div>
    </footer>
  )
}

export default Footer